import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/feeds',
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/SignIn.vue'),
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: () => import(/* webpackChunkName: "SignOut" */ '@/views/SignOut.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/Admin.vue'),
  },
  {
    path: '/feed/:id',
    name: 'Feed',
    component: () => import(/* webpackChunkName: "Feed" */ '@/views/feed/Feed.vue'),
  },

  {
    path: '/feed_price/:id',
    name: 'FeedPrice',
    component: () => import(/* webpackChunkName: "FeedPrice" */ '@/views/feed/FeedPrice.vue'),
  },
  {
    path: '/feeds',
    name: 'Feeds',
    component: () => import(/* webpackChunkName: "Feeds" */ '@/views/feeds/Feeds.vue'),
  },
  { path: '*', component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
